import { User, UserOptions } from "./user.model";
import { Booking } from "./booking.model";
import { Country } from "./country.model";
import { Communication } from "./communication.model";
import { Cloneable } from "./cloneable";
import { Utilities } from "../utilities/utilities";

export interface ClientOptions extends UserOptions {
  reminderType?: string;
  badEmail?: boolean;
  blockedOnline?: boolean;
  unsubscribed?: boolean;
  notes?: string;
  stylistID?: number;
  birthDate?: Date;
  sendEmail?: boolean;
  pronouns?: number;
  customPronouns?: string;
}

export interface ClientJSON {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phone1: string;
  phone2: string;
  workPh: string;
  workExt: string;
  address1: string;
  address2: string;
  city: string;
  province: string;
  postal: string;
  countryID: number;
  notes: string;
  reminderType: string;
  badEmail: number;
  blockedOnline: number;
  unsubscribed: number;
  stylistID: number;
  birthDate: string;
  sendEmail?: boolean;
  pronouns: number;
  customPronouns: string;
}

export class Client extends User implements Cloneable<Client> {
  private reminderType: string;

  private badEmail: boolean;

  private blockedOnline: boolean;

  private unsubscribed: boolean;

  private notes: string;

  // stylistID is required when creating a new Client
  private stylistID: number;

  public salonID: number;
  public pronouns: number;
  public customPronouns: string;

  private birthDate: Date;

  private bookingHistoryLoaded: boolean;

  private communicationHistoryLoaded: boolean;

  private bookings: Booking[];

  private communications: Communication[];

  private sendEmail: boolean;

  constructor(options?: ClientOptions) {
    options = options || {};
    super(options);
    this.reminderType = options.reminderType || "3";
    this.badEmail = options.badEmail || false;
    this.blockedOnline = options.blockedOnline || false;

    this.unsubscribed = options.unsubscribed || false;
    this.notes = options.notes || "";
    this.bookingHistoryLoaded = false;
    this.communicationHistoryLoaded = false;
    this.bookings = [];
    this.communications = [];
    this.stylistID = options.stylistID;
    this.birthDate = options.birthDate;
    this.sendEmail = options.sendEmail;
    this.pronouns = options.pronouns;
    this.customPronouns = options.customPronouns;
  }

  public clone(): Client {
    return new Client({
      id: this.getID(),
      firstName: this.getFirstName(),
      lastName: this.getLastName(),
      email: this.getEmail(),
      phone1: this.getPhone1(),
      phone2: this.getPhone2(),
      workPh: this.getWorkph(),
      workExt: this.getWorkExt(),
      address1: this.getAddress1(),
      address2: this.getAddress2(),
      city: this.getCity(),
      province: this.getProvince(),
      postal: this.getPostal(),
      country: this.getCountry() ? this.getCountry().clone() : undefined,
      reminderType: this.getReminderType(),
      badEmail: this.isBadEmail(),
      blockedOnline: this.isBlockedOnline(),
      unsubscribed: this.isUnsubscribed(),
      notes: this.getNotes(),
      stylistID: this.getStylistID(),
      birthDate: this.getBirthDate() ? this.getBirthDate() : undefined,
      pronouns: this.getPronoun(),
      customPronouns: this.getcustomPronouns(),
    });
  }

  public isCommunicationHistoryAlreadyLoaded(): boolean {
    return this.communicationHistoryLoaded;
  }

  public isBookingHistoryAlreadyLoaded(): boolean {
    return this.bookingHistoryLoaded;
  }

  public getReminderType(): string {
    return this.reminderType;
  }

  public setReminderType(reminderType: string): void {
    this.reminderType = reminderType;
  }

  public getNotes(): string {
    return this.notes;
  }

  public setNotes(newNotes: string) {
    this.notes = newNotes;
  }

  public isBadEmail(): boolean {
    return this.badEmail;
  }

  public setBadEmail(badEmail: boolean) {
    return (this.badEmail = badEmail);
  }

  public isUnsubscribed(): boolean {
    return this.unsubscribed;
  }

  public setUnsubscribed(subscribed: boolean) {
    this.unsubscribed = subscribed;
  }

  public isBlockedOnline(): boolean {
    return this.blockedOnline;
  }

  public setBlockedOnline(blockedOnline: boolean) {
    return (this.blockedOnline = blockedOnline);
  }

  public setStylistID(stylistID: number) {
    this.stylistID = stylistID;
  }

  public getStylistID(): number {
    return this.stylistID;
  }

  public getBirthDate() {
    return this.birthDate;
  }
  public getPronoun(): number {
    return this.pronouns;
  }

  public getcustomPronouns(): string {
    return this.customPronouns;
  }

  public setBirthDate(date: Date) {
    this.birthDate = date;
  }

  public setBirthMonth(month: number) {
    if (this.birthDate === undefined) {
      this.birthDate = new Date();
      this.birthDate.setDate(1);
    }

    this.birthDate.setMonth(month - 1);
  }

  public setBirthday(day: number) {
    if (this.birthDate === undefined) {
      this.birthDate = new Date();
      this.birthDate.setMonth(0);
    }

    this.birthDate.setDate(day);
  }

  public getCommunications(): Array<Communication> {
    return this.communications;
  }

  public setSendEmail(val: boolean) {
    this.sendEmail = val;
  }

  public getSendEmail(): boolean {
    return this.sendEmail;
  }

  public toJSON(): Object {
    const payload: ClientJSON = {
      firstName: this.getFirstName(),
      lastName: this.getLastName(),
      email: this.getEmail(),
      password: this.getPassword(),
      address1: this.getAddress1(),
      address2: this.getAddress2(),
      phone1: this.getPhone1(),
      phone2: this.getPhone2(),
      workPh: this.getWorkph(),
      workExt: this.getWorkExt(),
      city: this.getCity(),
      postal: this.getPostal(),
      province: this.getProvince(),
      countryID: this.getCountry() ? this.getCountry().getID() : null,
      notes: this.getNotes(),
      reminderType: this.getReminderType(),
      badEmail: this.unsubscribed ? 1 : 0,
      blockedOnline: this.blockedOnline ? 1 : 0,
      unsubscribed: this.unsubscribed ? 1 : 0,
      stylistID: this.stylistID,
      birthDate:
        this.birthDate !== undefined && this.birthDate !== null
          ? Utilities.formatDate(this.birthDate, "Y-MM-DD")
          : "0000-00-00",
      sendEmail: this.getSendEmail(),
      pronouns: this.getPronoun(),
      customPronouns: this.getcustomPronouns(),
    };

    return payload;
  }

  public static parseClientData(clientData) {
    let country: Country;
    if (clientData.countryID) {
      country = new Country(clientData.countryID, clientData.countryName);
    }
    return new Client({
      id: clientData.id,
      firstName: clientData.firstName,
      lastName: clientData.lastName,
      email: clientData.email,
      phone1: clientData.phone1,
      phone2: clientData.phone2,
      workPh: clientData.workPh,
      address1: clientData.address1,
      address2: clientData.address2,
      city: clientData.city,
      province: clientData.province,
      postal: clientData.postal,
      country: country,
      reminderType: clientData.reminderType,
      notes: clientData.notes,
      pronouns: clientData.pronouns,
      customPronouns: clientData.customPronouns,
      badEmail: clientData.badEmail === 1 ? true : false,
      blockedOnline:
        clientData.blockedOnline === 1 || clientData.blockedOnline === true
          ? true
          : false,
      birthDate:
        Utilities.parseDate(clientData.birthDate) !== null
          ? Utilities.parseDate(clientData.birthDate)
          : undefined,
    });
  }
}
