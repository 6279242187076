import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { Client } from "../models/client.model";
import { Booking } from "../models/booking.model";
import { UserService } from "../services/user.service";
import { IAuth } from "../services/login.service";

import { ErrorHandlerService } from "../services/error-handler.service";
import { SalonmonsterHttpClient } from "../services/salonmonster-http-client";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";

@Injectable()
export class ClientService extends SalonmonsterHttpClient {
  private emailCheckerClientID: number;

  constructor(
    http: HttpClient,
    private userService: UserService,
    private errorHandlerService: ErrorHandlerService
  ) {
    super(http);
  }

  public setEmailCheckerClientID(clientID: number) {
    this.emailCheckerClientID = clientID;
  }

  public emailChecker(email: string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      let url = `${environment.API_ROOT}/salons/${this.userService
        .getSalon()
        .getId()}/clients/emailchecker/${email}`;

      if (this.emailCheckerClientID) {
        url += `?clientID=${this.emailCheckerClientID}`;
      }

      this.get(url)
        .pipe(
          map((res: any) => {
            let body = res;
            const data = body.data;
            const available: boolean = data.available;
            return available;
          })
        )
        .subscribe(
          (available: boolean) => {
            // setTimeout(() => {
            observer.next(available);
            observer.complete();
            // }, 3000);
          },
          (error) => {
            observer.error(this.errorHandlerService.handleError(error));
            observer.complete();
          }
        );
    });
  }

  public getClientBookings(): Observable<Array<Booking>> {
    return new Observable<Array<Booking>>((observer) => {
      const url = `${environment.API_ROOT}/clients/me/bookings`;
      this.get(url)
        .pipe(
          map((res: any) => {
            let body = res;

            const data = body.data;
            const bookings: Array<Booking> = [];
            for (let bookingData of data) {
              bookings.push(Booking.parseBooking(bookingData));
            }

            return bookings;
          })
        )
        .subscribe(
          (bookings: Array<Booking>) => {
            observer.next(bookings);
            observer.complete();
          },
          (error) => {
            observer.error(this.errorHandlerService.handleError(error));
            observer.complete();
          }
        );
    });
  }

  public getClientInfo(): Observable<Client> {
    return new Observable<Client>((observer) => {
      let url = `${environment.API_ROOT}/clients/me`;
      this.get(url)
        .pipe(
          map((res: any) => {
            let body = res;
            const data = body.data;
            return Client.parseClientData(data[0]);
          })
        )
        .subscribe(
          (client: Client) => {
            observer.next(client);
            observer.complete();
          },
          (error) => {
            observer.error(this.errorHandlerService.handleError(error));
            observer.complete();
          }
        );
    });
  }

  public getClientInfoFromID(clientID: number): Observable<Client> {
    return new Observable<Client>((observer) => {
      let url = `${environment.API_ROOT}/clients/${clientID}`;
      this.get(url)
        .pipe(
          map((res: any) => {
            let body = res;
            const data = body.data;
            return Client.parseClientData(data[0]);
          })
        )
        .subscribe(
          (client: Client) => {
            observer.next(client);
            observer.complete();
          },
          (error) => {
            observer.error(this.errorHandlerService.handleError(error));
            observer.complete();
          }
        );
    });
  }

  public updateClient(client: Client): Observable<Client> {
    return new Observable<Client>((observer) => {
      let url = `${environment.API_ROOT}/clients/me`;
      this.put(url, client.toJSON())
        .pipe(
          map((res: any) => {
            const body = res;
            const data = body.data;
            return Client.parseClientData(data);
          })
        )
        .subscribe(
          (client: Client) => {
            observer.next(client);
            observer.complete();
          },
          (error) => {
            observer.error(this.errorHandlerService.handleError(error));
            observer.complete();
          }
        );
    });
  }

  public createClient(client: Client, salonID: number): Observable<Client> {
    return new Observable<Client>((observer) => {
      let url = `${environment.API_ROOT}/clients`;
      let body = client.toJSON();
      body["salonID"] = salonID;
      return this.post(url, body)
        .pipe(
          map((res: any) => {
            const body = res;
            const data = body.data;
            return Client.parseClientData(data);
          })
        )
        .subscribe(
          (client: Client) => {
            observer.next(client);
            observer.complete();
          },
          (error) => {
            observer.error(this.errorHandlerService.handleError(error));
            observer.complete();
          }
        );
    });
  }

  public deleteClient(client: Client, index?: number): Observable<Client> {
    return new Observable<Client>((observer) => {
      let url = `${environment.API_ROOT}/clients/${client.getID()}`;
      this.delete(url, {})
        .pipe(
          map((res: any) => {
            return client.clone();
          })
        )
        .subscribe(
          (client: Client) => {
            observer.next(client);
            observer.complete();
          },
          (error) => {
            observer.error(this.errorHandlerService.handleError(error));
            observer.complete();
          }
        );
    });
  }

  public quickLogin(key: string, salonID: number): Observable<IAuth> {
    return new Observable<IAuth>((observer) => {
      let url = `${environment.API_ROOT}/clients/me/quicklogin`;
      this.post(url, {
        quickLoginKey: key,
        salonID: salonID,
      })
        .pipe(
          map((res: any) => {
            return res;
          })
        )
        .subscribe(
          (response) => {
            const data = {
              salon: response.data.salon,
              token: response.data.token,
              user: response.data.user,
            };

            observer.next(data);
            observer.complete();
          },
          (error) => {
            observer.error(this.errorHandlerService.handleError(error));
            observer.complete();
          }
        );
    });
  }

  getClientCardRequest(requestHash: string): Observable<Client> {
    return new Observable<Client>((observer) => {
      let url = `${environment.API_ROOT}/clients/requestcard/${requestHash}`;
      this.get(url)
        .pipe(
          map((res: any) => {
            return res;
          })
        )
        .subscribe(
          (response) => {
            observer.next(response.data);
            observer.complete();
          },
          (error) => {
            observer.error(this.errorHandlerService.handleError(error));
            observer.complete();
          }
        );
    });
  }

  public insertClientPhoto(clientID, base64String) {
    const url = `${environment.API_ROOT}/clients/${clientID}/photos`;
    return this.post(url, {
      clientID: clientID,
      base64String: base64String,
    }).pipe(
      map((data) => {
        return data;
      })
    );
  }
}
