import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";

import { UserService } from "../../shared/services/user.service";
import { DialogsService } from "../../shared/services/dialogs/dialogs.service";
import { UserBookingService } from "../../shared/services/user-booking.service";
import { Booking } from "../../shared/models/booking.model";
import { Client } from "../../shared/models/client.model";
import { Service } from "../../shared/models/service.model";
import { BookingService } from "../../shared/services/booking.service";
import { ClientService } from "../../shared/services/client.service";
import { SalonService } from "../../shared/services/salon.service";
import {
  NotificationService,
  NOTIFICATION_TYPE,
} from "../../shared/services/notification.service";
import { Utilities } from "../../shared/utilities/utilities";
// tslint:disable-next-line:import-blacklist
import { from, Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { StripeService } from "../../shared/services/stripe.service";

@Component({
  templateUrl: "bookings-summary.component.html",
})
export class BookingsSummaryComponent implements OnInit {
  public booking: Booking;

  public title: string;

  public subTitle: string;
  public chargeOnNoShow = true;
  public loadStripeComponent = false;
  public clientHasStripeAccount = false;
  public isLoading = false;
  public refreshStripe = 0;
  //todo: pull chargeNoShow from salon service (true if  clientBookingPayments > 0)
  public clientBookingPayments: number;
  public noShowPercentage: number;
  public isAnyBooking: boolean;
  public enableBookButton: boolean;
  public savingStripe: boolean = false;
  constructor(
    public userService: UserService,
    private bookingService: BookingService,
    private userBookingService: UserBookingService,
    private salonService: SalonService,
    private router: Router,
    private dialogsService: DialogsService,
    private clientService: ClientService,
    private stripeService: StripeService,
    private notificationService: NotificationService,
    private snackBar: MatSnackBar,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.booking = this.userBookingService.getBooking();
    this.title = "Your booking so far:";
    this.subTitle = Utilities.formatDate(
      this.booking.getStartDateTime(),
      "h:mm a, dddd, MMMM D, Y"
    );
    this.isAnyBooking = this.booking
      .getServices()
      .some(
        (service) =>
          service.getServiceDefinition().getCommonStylists()?.size > 0
      );
    console.log("anybooking", this.isAnyBooking);
    this.enableBookButton = true;
  }

  public async ngOnInit() {
    if (
      (this.booking.getStatus() === 2 || this.booking.getStatus() === 3) &&
      this.booking.getServices().length === 0
    ) {
      await this.router.navigate(["/"]);
    }

    this.clientBookingPayments = this.userService
      .getSalon()
      .getClientBookingPayments();
  }

  public onPhotoUpdate(photos: Array<string>) {
    this.booking.setPhotos(photos);
  }

  /**
   * Handles the booking submission process with different flows for logged-in users and guests.
   * Controls the visibility of signup/login options based on stylist preferences.
   * Manages payment requirements before finalizing the booking.
   */
  public onBookingSave() {
    // Disable the book button to prevent double submissions
    this.enableBookButton = false;

    // Handle flow for logged-in users
    if (this.userService.isLoggedIn()) {
      // Verify all services have stylists selected before proceeding
      if (this.checkStylistsSelected()) {
        this.save(this.userService.getUser());
      } else {
        // Re-enable button if stylist validation fails
        this.enableBookButton = true;
      }
    }
    // Handle flow for guest users
    else {
      // Default to allowing both regular signup and Facebook login
      let allowSignUp = true;
      let allowFBLogin = true;
      let clientBookingPayments = this.clientBookingPayments;

      // Check each service's stylist preferences to determine signup options
      for (let service of this.booking.getServices()) {
        if (service.getServiceDefinition()) {
          const stylist = service.getStylist();

          // Disable regular signup if any stylist has disabled client registration
          if (stylist && stylist.getLetClientsRegister() === 0) {
            allowSignUp = false;
          }
          // Disable Facebook login if any stylist has disabled FB login
          if (stylist && stylist.getLetClientsUseFBLogin() === 0) {
            allowFBLogin = false;
          }
        }
      }

      // Show login dialog with configured options
      this.dialogsService
        .login(allowSignUp, allowFBLogin)
        .subscribe((client: Client) => {
          // Handle successful login/signup
          if (client) {
            // Check if salon requires payment during booking
            if (this.clientBookingPayments > 0) {
              // Trigger Stripe component refresh to collect payment info
              this.refreshStripe++;
              this.enableBookButton = true;
            } else {
              // Proceed with booking if no payment required
              this.save(client);
            }
          } else {
            // Re-enable button if login/signup was cancelled
            this.enableBookButton = true;
          }
        });
    }
  }

  private checkStylistsSelected(): boolean {
    for (let service of this.booking.getServices()) {
      if (service.getServiceDefinition()) {
        const stylist = service.getStylist();
        if (!stylist) {
          this.snackBar.open("Please select a service provider", "Close", {
            duration: 5000,
            panelClass: ["error-snackbar"],
            horizontalPosition: "center",
            verticalPosition: "top",
          });
          // Reactivate the "Book it" button
          this.savingStripe = false;
          // Trigger change detection
          this.changeDetectorRef.detectChanges();
          return false;
        }
      }
    }
    return true;
  }

  private save(client: Client) {
    this.isLoading = true;
    let notificationType: NOTIFICATION_TYPE = NOTIFICATION_TYPE.NEW_BOOKING;
    this.booking.setClient(client);
    this.clientService.getClientInfo().subscribe(
      (client: Client) => {
        this.userService.setUser(client);
        // if the user is blocked from booking online, don't save the booking
        if (this.userService.getUser().isBlockedOnline()) {
          this.dialogsService
            .alert(
              "  ",
              "I'm sorry, we're unable to take your online booking. Please contact the salon by phone."
            )
            .subscribe(() => {
              this.router.navigate(["bookings"]);
            });
          this.enableBookButton = true;
          return;
        }

        // default (Unconfirmed status)
        let status = 3;

        // if one of the stylists in this booking has confirmAppointmentsManually turned on,
        // set the booking status to requested
        for (let service of this.booking.getServices()) {
          if (service.getServiceDefinition()) {
            const stylist = service.getStylist();
            if (stylist && stylist.getConfirmApptsManually() === 1) {
              // requested
              status = 2;
              notificationType = NOTIFICATION_TYPE.NEW_BOOKING_REQUEST;
            }
          }
        }

        if (this.booking.getId()) {
          notificationType = NOTIFICATION_TYPE.BOOKING_EDITED;
        }

        this.checkBookingOverlaps()
          .pipe(
            switchMap((overlaps: Array<Service>) => {
              if (overlaps.length > 0) {
                this.dialogsService
                  .alert(
                    "Booking Overlap",
                    "Sorry but this timeslot is already taken. Please pick a new timeslot for your booking."
                  )
                  .subscribe();
                this.router.navigate(["stylists/availabilities"]);
                return;
              }

              this.booking.setStatus(status);
              return from(this.imageUpload(client, this.booking.getPhotos()));
            }),
            switchMap((booking) => {
              return this.bookingService.saveBooking(booking);
            })
          )
          .subscribe(
            (booking: Booking) => {
              if (!booking) {
                this.enableBookButton = true;
                this.isLoading = false;
                return;
              }

              this.notificationService
                .send(booking, notificationType)
                .subscribe(() => {
                  this.isLoading = false;
                  this.router.navigate(["bookings/confirmation"]);
                });
            },
            (error) => {
              this.isLoading = false;
              this.enableBookButton = true;
              throw error;
              // this.dialogsService.errorAlert().subscribe();
            }
          );
      },
      (error) => {
        console.log("error on save:", error);
        if (error.error.err_msg === "Client not found") {
          console.log("err msg client not found");
          this.enableBookButton = true;
          // user not found, log out and instead of saving make them log in first
          this.userService.logout();
          this.onBookingSave();
        } else {
          throw error;
        }
        // this.dialogsService.errorAlert().subscribe();
      }
    );
  }

  private checkBookingOverlaps(): Observable<Array<Service>> {
    return new Observable<Array<Service>>((observer) => {
      let overlaps: Array<Service> = [];
      let counter: number = 0;
      let successCount: number = 0;
      console.log("booking services:", this.booking.getServices());
      for (let service of this.booking.getServices()) {
        console.log("in services loop:", service);
        this.bookingService
          .bookingOverlapChecker(this.booking, service)
          .subscribe(
            (result) => {
              successCount++;
              overlaps.push(...result);

              if (successCount === this.booking.getServices().length) {
                observer.next(overlaps);
                observer.complete();
              }
            },
            (err) => {
              observer.error(err);
              observer.complete();
            }
          );
      }
    });
  }

  imageUpload(client: Client, photos: Array<string>) {
    let photoUploadPromises = photos.map((photo) =>
      this.clientService.insertClientPhoto(client.getID(), photo).toPromise()
    );

    return Promise.all(photoUploadPromises).then((photoRes) => {
      photoRes = photoRes.map((res) => res.data);
      this.booking.setPhotos(photoRes);
      return this.booking;
    });
  }
}
